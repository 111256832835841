
// import React from "react";
// import "./Message.css";

// const Message = ({ text, createdAt, from }) => (
//   <div className="msg-class">
//     <p className={`message ${from}`}>
//       {/* <div className="icon"></div> */}
//       {text} <span className="message-time">{createdAt}</span>
//     </p>
//   </div>
// );

// export default Message;

///////////////Latest code is below/////////////
import React from "react";
import "./Message.css";
import Linkify from 'react-linkify'
 
const Message = ({ text, createdAt, from }) => {
      const customDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );
  return(
<div className="msg-class">
<Linkify componentDecorator={customDecorator}>
<p className={`message ${from}`}>
{text} <span className="message-time">{createdAt}</span>
</p>
</Linkify> 
<div className="clear-float"></div>
</div>

);
}
 
export default Message;
