 import config from "./utils/config.js";
export const msalConfig = {
  auth: {
    clientId: config.AppID,
   
    authority:`https://login.microsoft.com/${config.AppTenantID}`,
   
    redirectUri: config.AppRedirectURL,
    
    graphapi:'https://graph.microsoft.com/v1.0/me',
    navigateToLoginRequestUrl: true
  },

  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
    scopes : [ "user.read"],
};
