/*

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { msalConfig, loginRequest } from './authConfig';
import { PublicClientApplication, Configuration } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
//import microsoftTeams from "@microsoft/teams-js";
import { BrowserRouter as Router} from 'react-router-dom';

console.log("inside Index.js:::::")
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  //<AppWithAuthentication />
  //<BrowserRouter>
  <App />
  
  //</BrowserRouter>


);
*/

//import React, { useEffect, useState } from "react";
//import "./Header.css";
 
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
 
// import { useNavigate } from 'react-router-dom';
//import { useMsal } from "@azure/msal-react";
//import { msalConfig } from "../authConfig";
//import { PublicClientApplication } from "@azure/msal-browser";
 
//import axios from "axios";

////////

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { acquireAccessToken, acquireUserProfile } from "./authUtil";
import { useMsal } from '@azure/msal-react';
import logoutAndRedirect from './components/logout';

import './index.css';
import ReactDOM from 'react-dom';
import App from './App';
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";  // Assuming your main app component is named App
var dbdocdbid
var passwordssprstate
var differenceInDays
var notlogout
var dbpasswordreset
var newSessionId
var token = sessionStorage.getItem('swiss.api.access_token');
const msalInstance = new PublicClientApplication(msalConfig);
const accounts = msalInstance.getAllAccounts();
  const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""
}`;
const user = accounts[0]
//  async function tokengen (){
   
// //const token = sessionStorage.getItem("swiss.api.access_token");
//   var tokendetails =  await acquireAccessToken(accounts[0].idToken)
//   token = tokendetails.access_token
//   console.log("tokennnn in index.js", token)
//  }
//  tokengen()
var baseurl =sessionStorage.getItem('swiss.api.baseUrl');
console.log("baseurl--------------",baseurl)

  passwordssprstate = sessionStorage.getItem("passwordchange")

  var dblanguage =sessionStorage.getItem("language")
  var conversationHistory =sessionStorage.getItem("chattranscript")
  //var dbstarttime =sessionStorage.getItem("starttime")
  
//var dblastupdatedtime = sessionStorage.getItem("dblastupdatedtime")
  //var dbjustdate =sessionStorage.getItem("date")
  var dbtimestamp =sessionStorage.getItem("timestamp")
  var dbssprlink =sessionStorage.getItem("ssprlink")
  newSessionId = sessionStorage.getItem("sessionid")
  console.log("previous session id of same user in index.js", newSessionId)
 

  async function getpasswordinformation() {
    let data = JSON.stringify({
      emailid: user.username
  
    });
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/getpassworddetails?aa=bb`,
      headers: {
        'Content-Type': 'application/json',
        "authorization": `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios.request(config)
      console.log("Response from password", response)
      return response.data
  
    }
    catch (error) {
      console.log("Error while getting password details in chatbot.js file", error)
    }
  }
  
  async function calculateDaysDifference(inputTimestamp) {
    try {
      // Parse the input timestamp and extract only the date
      const inputDate = new Date(inputTimestamp.split("T")[0]);
      inputDate.setHours(0, 0, 0, 0)
      if (isNaN(inputDate.getTime())) {
        throw new Error("Invalid date format. Please provide a valid timestamp.");
      }
  
      // Get the current date and normalize it to only the date part
      const currentDate = new Date();
      console.log("current dateee", currentDate)
      currentDate.setHours(0, 0, 0, 0);
  
      // Calculate the difference in milliseconds and convert to days
      const differenceInMilliseconds = currentDate - inputDate;
      differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24)
  
      console.log(`Difference in days: ${differenceInDays}`);
      differenceInDays = 90 - differenceInDays
      console.log(`Password is going to expire in :${differenceInDays}`)
    } catch (error) {
      console.error(error.message);
    }
  }
 
  /////////////////
  async function second(){
    //      const [sso, setSso] = useState(false);
    // const { accounts } = useMsal();
    if (passwordssprstate) {
      var responsefrompasswordinformation
    
      async function timewaiter (params) {
        console.log("inside timewaiter function")
        //await new Promise((resolve)=> setTimeout(resolve, 10000))
        //await new Promise((resolve)=> (resolve))
        // setTimeout( async() => {
          responsefrompasswordinformation = await getpasswordinformation()
        //}, 4000);
        
     }
      await timewaiter()
    
      //const responsefrompasswordinformation = await getpasswordinformation()
      console.log("Responsee after user changed the password", responsefrompasswordinformation)
      await calculateDaysDifference(responsefrompasswordinformation)
      console.log("New password expiry", differenceInDays)
      if (differenceInDays === 90) {
        //logout = true
        console.log("User changed the password using web and refreshed the page")
        // dbpasswordreset = "True"
        // await updatedb()
        dbpasswordreset = "Yes"
        console.log("Logoutttttttttt")
        sessionStorage.removeItem("passwordchange")
        //dbpasswordreset = true
        dbpasswordreset = "Yes"
        dbdocdbid = "sampledocid"
        await updatedb()
        await logoutAndRedirect(msalInstance)
       
      }
      else {
        notlogout = true
        // console.log("Don't logout", notlogout)
        
        sessionStorage.removeItem("passwordchange")
        console.log("No password change and user refrsehed after changing the password ----")
        //routetobot()
      }
    
    }
    
    }


const updatedb = async () => {
  //console.log("Before updating the dbchangepassword state ---", dbchangepassword)

  let data = JSON.stringify({
    environment:baseurl,
    session: newSessionId,
    dbpasswordreset: dbpasswordreset,
    dbdocdbid: dbdocdbid,
    dbliveagent:"No",
    dblanguage: dblanguage,
      dbemail: user.username,
      dbconv: conversationHistory,
    // dbstarttime: dbstarttime,
    //   dbjustdate: dbjustdate,
      dbtimestamp: dbtimestamp,
      dbssprlink: "Yes",
      

  });
 
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/updatedb?aa=bb`,
    headers: {
      'Content-Type': 'application/json',
      "authorization": `Bearer ${token}`
    },
    data: data
  };
  try {
    const response = await axios.request(config)
    console.log("Data in update doc db function in index.js", response)
    return response.data

  }
  catch (error) {
    console.log("Error in update db function in chatbot.js file", error)
  }
}


    
const Index = () => {
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  //const { instance } = useMsal();
 
  // Get all accounts
  
// Set default based on your app logic
//notlogout = true
  useEffect(() => {
    //Check if parameter exists in sessionStorage
    const sessionParameter = sessionStorage.getItem('passwordchange');
 
    if (sessionParameter) {
      setLoading(true); // Start showing "Please wait" message
 
      // Simulate the delay for 10 seconds
      
      //second()
      
    setTimeout(async () => {
        // Simulate API response handling (replace with your actual API call logic)
        // Example: If the password is changed, log out user
       //const apiResponse = { success: true };  // Change based on your API response
       
         
         //console.log("AFter calling second function", apiResponse.success)
        //if (apiResponse.success == true) {
          console.log("Before calling second function")
        await second()
        console.log("After calling second function")
        if(notlogout == true){
        
          console.log("inside  if")
          setLoading(false);
          setIsLoggedIn(true);  // Keep the user logged in
        }
         else {
          console.log("inside else")
          setIsLoggedIn(false);
         // logoutAndRedirect(msalInstance)  // Log out user
        }
        
        //setLoading(false);  // Stop showing "Please wait" message
      }, 18000);  // 10 seconds delay
    }

    // async function second(){
    //   //      const [sso, setSso] = useState(false);
    //   // const { accounts } = useMsal();
    //   if (passwordssprstate) {
    //     setLoading(true);
    //     var responsefrompasswordinformation
      
    //     async function timewaiter (params) {
    //       console.log("inside timewaiter function")
    //       await new Promise((resolve)=> setTimeout(resolve, 10000))
    //       // setTimeout( async() => {
    //         responsefrompasswordinformation = await getpasswordinformation()
    //       //}, 4000);
         
          
    //    }
    //     await timewaiter()
      
    //     //const responsefrompasswordinformation = await getpasswordinformation()
    //     console.log("Responsee after user changed the password", responsefrompasswordinformation)
    //     await calculateDaysDifference(responsefrompasswordinformation)
    //     console.log("New password expiry", differenceInDays)
    //     if (differenceInDays === 90) {
    //       //logout = true
    //       console.log("User changed the password using web and refreshed the page")
    //       // dbpasswordreset = "True"
    //       // await updatedb()
    //       console.log("Logoutttttttttt")
    //       sessionStorage.removeItem("passwordchange")
    //       await logoutAndRedirect(msalInstance)
    //     }
    //     else {
    //       // notlogout = true
    //       // console.log("Don't logout", notlogout)
    //       setIsLoggedIn(true)
          
    //       sessionStorage.removeItem("passwordchange")
    //       console.log("No password change and user refrsehed after changing the password ----")
    //       //routetobot()
    //     }
      
    //   }
    //   else{
    //     console.log("No pass changeeeee")
    //     setIsLoggedIn(true)
        
    //   }
      
    //   }
    //   second()
  }, []);  // This effect runs once when the component is mounted
 
  return (
    <div>
      {loading ? (
        <div>Please wait...</div>  // Display this while waiting for the API call
      ) : isLoggedIn ? (
        <App />  // Render the app if logged in
      ) : (
        <div>You have been logged outttt.</div>  // Show logout message if not logged in
      )}
    </div>
  );
};
 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Index />);